import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { useNavigate, useMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'ramda'
import { myProfile } from 'service/crmService'
import { updateUrlLang } from 'utils/util'
// import sideBar from 'constants/sideBar.json'
import { filterName, navigateLink } from 'utils/filter'
import { header } from 'service/headerService'
const Wrap = tw.div`bg-white w-[320px] hidden lg:block`
const TopBox = tw.div`cursor-pointer text-secondary-midnightBlue border-b-[1px] border-solid border-secondary-grey px-[60px] pt-[45px] pb-6 `
const BomBox = tw.div`text-secondary-midnightBlue text-base pt-8`

const Label = ({ item, keyLabel, fun }) => {
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <div
            onClick={fun}
            className={
                'mb-6 py-1 cursor-pointer flex  items-center gap-[30px] font-semiboldFamily px-5 md:px-[60px] ' +
                (keyLabel === item.linkUrl
                    ? ' text-primaryRed-ktRed border-solid border-r-[3px] border-primaryRed-ktRed'
                    : ' text-secondary-midnightBlue')
            }
        >
            <span className='size-6'>
                <img
                    src={keyLabel === item.linkUrl ? item.iconRedUrl : item.iconDefalutUrl}
                    alt=''
                    className='w-full h-full object-contain'
                />
            </span>
            <div>{filterName(item, language)}</div>
        </div>
    )
}

export default function Aside() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const bookmark = useMatch('/userSettings/myBookmark')
    const record = useMatch('/userSettings/myRecord')
    const setting = useMatch('/userSettings/setting')
    const [user, setUser] = useState({})
    const [keyLabel, setKeyLabel] = useState(null)

    const { data: sideBarData } = useQuery({
        queryKey: ['getSideBar'],
        queryFn: header.getSideBar,
        placeholderData: null,
    })
    const sideBar = sideBarData ? JSON.parse(sideBarData) : null
    const { data: profileData } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })

    useEffect(() => {
        setKeyLabel(
            record
                ? process.env.REACT_APP_WEBSITE + '/userSettings/myRecord'
                : setting
                ? process.env.REACT_APP_WEBSITE + '/userSettings/setting'
                : null,
        )
    }, [location])

    useEffect(() => {
        if (!isEmpty(profileData)) {
            setUser(profileData)
        }
    }, [profileData])

    return (
        <Wrap>
            {user && (
                <TopBox className='font-semiboldFamily'>
                    {user?.firstname && <div className='text-[22px] leading-[26.4px]'>{user.firstname}</div>}
                    {user?.memberId && (
                        <div className='font-medium text-xs pt-1 line-clamp-2 w-full'>
                            {t('myProfile.userId') + ' ' + user.memberId}
                        </div>
                    )}
                    <div
                        onClick={() => {
                            navigate('/userSettings/myProfile')
                        }}
                        className='text-sm underline py-[10px]'
                    >
                        {t('myProfile.manageProfile')}
                    </div>
                </TopBox>
            )}
            <BomBox className='font-medium'>
                {sideBar &&
                    sideBar.length > 0 &&
                    sideBar
                        .filter(
                            (i) =>
                                i.linkUrl !== process.env.REACT_APP_WEBSITE + '/userSettings/myProfile' &&
                                !i.linkUrl.includes('/Logout'),
                        )
                        .map((item, index) => {
                            return (
                                <Label
                                    item={item}
                                    index={index}
                                    keyLabel={keyLabel}
                                    key={index}
                                    fun={() => {
                                        item.platform === 'CP' && setKeyLabel(item.linkUrl)
                                        item?.platform === 'VBS'
                                            ? window.open(updateUrlLang(item.linkUrl))
                                            : navigateLink(navigate, 0, item.linkUrl)
                                    }}
                                />
                            )
                        })}
            </BomBox>
        </Wrap>
    )
}
