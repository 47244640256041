import React, { useEffect, useState, useMemo } from 'react'
import Menu from '@mui/material/Menu'
import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import Label from 'components/headerComponents/Label'
import { myProfile } from 'service/crmService'
import { event } from 'event/event'
import { isEmpty } from 'ramda'
import { updateUrlLang } from 'utils/util'
import { filterName, navigateLink } from 'utils/filter'

const Avatar = tw.div`text-white w-[42px] h-[42px] cursor-pointer rounded-[21px] bg-[#8F774E] border-solid border-[1.5px] border-white flex justify-center items-center text-sm`
function MenuHeader({ handleClick, anchorEl, handleClose, open, useData, isPending, profileData }) {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [nickName, setNickName] = useState('')
    const [lastName, setLastName] = useState('')
    const [memberId, setMemberId] = useState('')
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const text = useMemo(() => {
        if (nickName && lastName) {
            return lastName.slice(0, 1) + nickName.slice(0, 1)
        } else if (lastName) {
            return lastName.slice(0, 1)
        }
    }, [nickName, lastName])

    useEffect(() => {
        if (!isPending && !isEmpty(profileData)) {
            setNickName(profileData?.firstname)
            setLastName(profileData?.lastname)
            setMemberId(profileData?.memberId)
        }
    }, [profileData, isPending])
    if (!lgScreen) {
        return null
    }
    return (
        <div className={'hidden lg:block'}>
            <Avatar className='font-semiboldFamily' onClick={handleClick}>
                {text}
            </Avatar>
            <Menu
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: 0,
                        borderTopRightRadius: '40px',
                        translate: { xs: 0, md: -30 },
                        marginTop: 2,
                    },
                    '.MuiList-root': {
                        paddingY: 0,
                    },
                }}
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                        padding: '16px 20px',
                        color: '#231448',
                    }}
                >
                    {nickName && (
                        <Box sx={{ fontWeight: 600, fontSize: 20, lineHeight: 1.2, color: '#283266' }}>{nickName}</Box>
                    )}
                    {memberId && (
                        <Box
                            sx={{
                                color: '#666666',
                                fontSize: 12,
                                fontWeight: 500,
                                lineHeight: 1,
                                lineClamp: 1,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '160px',
                                overflow: 'hidden',
                                textAlign: 'center',
                                paddingTop: 1,
                            }}
                        >
                            {t('myProfile.userId') + ' ' + memberId}
                        </Box>
                    )}
                    <Box
                        onClick={() => {
                            navigate('/userSettings/myProfile')
                            handleClose()
                        }}
                        sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: 1,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#283266',
                            paddingTop: 2,
                        }}
                    >
                        {t('myProfile.myProfile')}
                    </Box>
                </Box>
                {useData &&
                    useData.length > 0 &&
                    useData
                        .filter((i) => i.linkUrl !== process.env.REACT_APP_WEBSITE + '/userSettings/myProfile')
                        .map((item, index) => {
                            return (
                                <Label
                                    icon={<img src={item.iconDefalutUrl} alt='' className='size-6' />}
                                    text={filterName(item, language)}
                                    handleFun={() => {
                                        handleClose()
                                        item?.platform === 'VBS'
                                            ? window.open(updateUrlLang(item.linkUrl))
                                            : !item.linkUrl.includes('/Logout')
                                            ? navigateLink(navigate, 0, item.linkUrl)
                                            : event.emit('logout')
                                    }}
                                    key={index}
                                />
                            )
                        })}
            </Menu>
        </div>
    )
}

export default MenuHeader
