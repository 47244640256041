import React from 'react'
import { useTranslation } from 'react-i18next'

import TaxiEn_Tc from 'resources/images/TaxiEn_Tc.jpg'
import TaxiSc from 'resources/images/TaxiSc.jpg'
import TaxiFoldSc from 'resources/images/TaxiFoldSc.png'
import TaxiFoldEn_Tc from 'resources/images/TaxiFoldEn_Tc.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import TaxiFerrryCrossCommon from 'page/Transportation/components/TaxiFerrryCrossCommon'
import BusCommonComponent from './BusCommonComponent'
function Taxi() {
    const {
        i18n: { language },
        t,
    } = useTranslation()

    const taxiItem = [
        {
            image: '',
            wording: {
                routes: [],
                title: t('transport.taxiFoldTitle_1'),
                detail: t('transport.taxiFoldDesc_1'),
            },
        },
        // {
        //     image: language === 'zh_CN' ? TaxiFoldSc : TaxiFoldEn_Tc,
        //     wording: {
        //         routes: [],
        //         title: t('transport.taxiFoldTitle_2'),
        //         detail: t('transport.taxiFoldDesc_2'),
        //     },
        // },
    ]

    return (
        <WrapBox
            image={language === 'zh_CN' ? TaxiSc : TaxiEn_Tc}
            component={
                <TaxiFerrryCrossCommon firstTitle={t('transport.byTaxi')} firstAbout={t('transport.taxiAbout')} />
            }
            bomComponent={<BusCommonComponent type='taxi' busItems={taxiItem} />}
        />
    )
}

export default Taxi
