import { useMutation, useQuery } from '@tanstack/react-query'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import Loading from 'components/Loading'
import Banner from 'components/shopDine/Banner'
import { TabContainer } from 'components/TabContainer'
import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import bannerTop from 'resources/images/friends1.jpg'
import friends2 from 'resources/images/friends2.png'
import friends3 from 'resources/images/friends3.png'
import friends4 from 'resources/images/friends4.png'
import friends_icon1 from 'resources/images/friends_icon1.png'
import friends_icon2 from 'resources/images/friends_icon2.png'
import { information } from 'service/informationService'
import { shopDineService } from 'service/restaurantService'
import { useModalStore } from 'store/authAtom'
import { Box, DialogContent, DialogTitle } from '@mui/material'
import Content from 'components/Content'
import 'page/Friends/index.css'
const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: '1px solid ' + theme.palette.divider, // 上边的分割线
}))

export default function Friends() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const { setModalComponent, onOpen, setColorCloseIcon } = useModalStore()

    const banner = {
        image: bannerTop,
        title: t('friends.title'),
        about: t('friends.bannerAbout'),
    }
    const { data: infoData, isFetching } = useQuery({
        queryKey: ['getAdditionalInformation', language],
        queryFn: () => information.getAdditionalInformation({ lang: language, type: [5] }),
        placeholderData: [],
        enabled: !!language,
    })
    const TermsOfCondition = () => {
        return (
            <div className='flex flex-col'>
                <div className='text-h6 text-secondary-midnightBlue break-words'>{t('friends.tncTitle')}</div>
                <div className='flex-1'>
                    <div className='text-body4 text-secondary-darkGery break-words mt-8 max-h-[50vh] overflow-y-auto thinScrollBar'>
                        <Content className='pt-4' data={infoData?.[0]?.content || ''}></Content>
                    </div>
                </div>
            </div>
        )
    }

    if (isFetching) return <Loading />

    return (
        <div>
            <Banner banner={banner} />
            <section className='mt-12'>
                <TabContainer
                    fullWidth={true}
                    components={[
                        {
                            key: 'Overview',
                            title: t('friends.overview'),
                            component: () => (
                                <div className='bg-white bg-container whitespace-pre text-wrap text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6 mt-10'>{t('friends.overview')}</div>
                                    {/* <img src={friends2} alt='loading' className='mb-6' /> */}
                                    <div className='mb-[60px] text-body1'>
                                        <div>{t('friends.desc')}</div>
                                        <br />
                                        <div>{t('friends.sub1')}</div>
                                        <br />
                                        <div>{t('friends.sub2')}</div>
                                        <br />
                                        <Trans
                                            i18nKey='friends.desc2'
                                            components={[
                                                <span
                                                    className='text-primaryRed-ktRed cursor-pointer'
                                                    onClick={() => {
                                                        setModalComponent(<TermsOfCondition />)
                                                        setColorCloseIcon('white')
                                                        onOpen()
                                                    }}
                                                />,
                                            ]}
                                        />
                                    </div>
                                    <div className='h-[1px] w-full bg-[#b1b1b1] mb-10' />
                                </div>
                            ),
                        },
                        {
                            key: 'points',
                            title: t('friends.membersExclusiveBenefits'),
                            component: () => (
                                <div className='bg-white bg-container  text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6'>{t('friends.membersExclusiveBenefits')}</div>
                                    {/* <img src={friends4} alt='loading' className='mb-6' /> */}
                                    <div className='text-body1 mb-10 lg:mb-[60px]'>
                                        <div className='mb-6'>{t('friends.desc3')}</div>
                                    </div>

                                    {/* <div className='flex lg:flex-row flex-col mb-20 gap-8 text-secondary-midnightBlue'>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon1} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem as Cash</div>
                                                <div className='text-body1'>
                                                    Earned KTSP Points can be used for future purchases
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon2} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem a Reward</div>
                                                <div className='text-body1'>
                                                    Redeem your earned points for exclusive rewards, discounts or
                                                    experiences.
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            ),
                        },
                    ].filter((i) => i)}
                />
            </section>
        </div>
    )
}
