import * as React from 'react'
import { IconButton, CardMedia, Card, CardActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { cn } from 'utils/cn'

import bookmarkHit from 'resources/images/bookmarkHit.png'
import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { dateRangeFormat } from 'utils/filter'
const BookmarkIcon = tw.div`text-secondary-midnightBlue text-xl px-[2px]`
function EventCard({ card, listType, index, isLanding = false, path = false }) {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isFull =
        card.enrlMethod && card.enrlMethod.includes('FCFS') && card.actionLink && card?.actionLink === 'disabled'
    const labelNow = isFull ? t('facilitiesBooking.full') : card.label
    return (
        <Card
            onClick={() => navigate((path ? path : '/eventsTicketsDetail') + '?id=' + card.uuid)}
            className={
                'relative w-full shadow-none cursor-pointer bg-transparent group rounded-none' +
                (!listType ? ' mt-[35px]' : '')
            }
            square={true}
        >
            <div className={!listType ? 'flex justify-between' : ''}>
                {/* {listType === 'calendar' && card.dateRange && (
                        <div className='p-3 w-[20%] bg-[#E9ECF3] h-full text-lg font-semiboldFamily md:w-[97px]'>
                            {dateRangeFormat(card.dateRange, language)}
                        </div>
                    )} */}
                <div
                    className={cn(
                        CARD_BOX,
                        'aspect-4/3 w-full h-full',
                        !listType && 'w-[40%] md:w-[27%]',
                        // listType === 'calendar' && 'xl:w-[250px] xl:h-[210px]',
                    )}
                >
                    {card.cardImage && (
                        <CardMedia
                            component='img'
                            className='w-full h-full object-cover group-hover:scale-110 transition-all duration-500'
                            image={card.cardImage}
                            alt='loading'
                            loading='lazy'
                        />
                    )}
                    {labelNow && (
                        <div
                            className={cn(
                                'absolute font-semiboldFamily text-white top-0 left-0 py-[6px] px-[15px] text-xs md:text-sm',
                                isFull ? 'bg-secondary-grey' : 'bg-primaryRed-ktRed',
                            )}
                        >
                            {labelNow}
                        </div>
                    )}
                    <RedLine />
                </div>
                <div className={cn(listType ? ' pt-3 flex-1' : 'w-[54%] md:w-[70%]')}>
                    <div className='flex justify-between '>
                        <div className={'text-secondary-midnightBlue w-full pb-2 text-h5 break-words'}>
                            {card.eventName}
                        </div>
                        {/* <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    console.log(3333)
                                }}
                                className={
                                    'p-0 self-start text-secondary-midnightBlue ' +
                                    (listType !== 'grid' && ' absolute top-0 right-0')
                                }
                            >
                                {card.bookMark ? (
                                    <img alt='' src={bookmarkHit} className='w-6 h-6 object-contain' />
                                ) : (
                                    <BookmarkIcon className='icon-bookmark' />
                                )}
                            </IconButton> */}
                    </div>
                    {card.description && !listType && <DescriptionLabel description={card.description} />}
                    {(card.displayDate || (path && card.dateRange && card.dateRange.length > 0)) &&
                        listType !== 'calendar' && (
                            <div className='flex gap-1 font-medium text-secondary-midnightBlue text-body3 pb-[6px]'>
                                {/* <IconButton className='p-0'> */}
                                <div className='icon-date text-base leading-4' />
                                {/* </IconButton> */}
                                {path ? (
                                    <div className='line-clamp-2'>{dateRangeFormat(card.dateRange, language)}</div>
                                ) : (
                                    <div className='line-clamp-2 textarea-content'>{card.displayDate}</div>
                                )}
                            </div>
                        )}
                    {card?.local && (
                        <div className='flex gap-1 font-medium text-secondary-midnightBlue text-body3'>
                            {/* <IconButton className='p-0'> */}
                            <div className='icon-location text-base leading-4' />
                            {/* </IconButton> */}
                            <div className='line-clamp-2'>{card.local}</div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    )
}

export default EventCard
