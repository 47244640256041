import React, { useState, useMemo } from 'react'
import { useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useMeasure } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { configDataStore } from 'store/authAtom'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import tw from 'twin.macro'
import { cn } from 'utils/cn'

const Title = tw.div`text-secondary-midnightBlue text-base lg:hidden`
const Wrap = tw.div`overflow-x-hidden rounded-[20px] bg-white p-3 md:p-8 lg:px-10 lg:py-8 mx-auto`
function BrandSwiper({ menu }) {
    const { configDataValue } = configDataStore()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const [ref, { width }] = useMeasure()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const ispad = useMediaQuery('(min-width:768px)')
    const isXl = useMediaQuery('(min-width:1440px)')
    const num = isXl ? 8 : ispad ? 6 : 4
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }

    const shuffleArray = (array = []) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const foundingPartner = useMemo(() => {
        const list = menu.foundingPartner.foundingPartnerList.slice(0, menu.foundingPartner.topNum)
        const randomList = shuffleArray(
          menu.foundingPartner.foundingPartnerList
              .slice(menu.foundingPartner.topNum, menu.foundingPartner.foundingPartnerList.length)
        )
        return list.concat(randomList)
    }, [menu.foundingPartner.foundingPartnerList])

    if (configDataValue && !configDataValue?.ourPartnersSwitch) {
        return null
    }

    return (
        <div className='bg-[#F5F5F5]'>
            <Wrap>
                <div className='hidden lg:flex lg:justify-between lg:items-center'>
                    <div className='flex text-body2 text-secondary-midnightBlue'>
                        <div className='text-[18px]'>{t('landing.premierFoundingPartner')}</div>
                        <div style={{
                            paddingLeft: language === 'en_US' ? width - 45 : width - 17,
                            display: !!foundingPartner.length ? '' : 'none'
                        }}>
                            {t('landing.foundingPanter')}
                        </div>
                    </div>
                    {
                        !!foundingPartner.length && (
                            <IconButtonChange
                               goToNextSlide={goToNextSlide}
                               goToPrevSlide={goToPrevSlide}
                               buttonColor={'#231448'}
                               borderColor={'border-secondary-midnightBlue'}
                               slideIndex={slideIndex}
                               length={menu.foundingPartner.foundingPartnerList.length}
                               num={num >= menu.foundingPartner.foundingPartnerList.length ? menu.foundingPartner.foundingPartnerList.length : num}
                               width='md:h-[42px] md:w-[42px] '
                            />
                      )
                    }
                </div>
                <div className='gap-[40px] lg:flex lg:items-center'>
                    <Title className='font-semiboldFamily text-[18px]'>{t('landing.premierFoundingPartner')}</Title>
                    <div className={cn(
                        'border-[#B1B1B1] lg:border-r-[1px] lg:py-[20px]',
                          language === 'en_US' ? 'lg:pr-[118px]' : 'lg:pr-[86px]',
                          !!foundingPartner.length ? 'border-solid' : 'border-none'
                        )}>
                        {menu.premierFoundingPartner && (
                            <img
                                onClick={() => {
                                    menu.premierFoundingPartner.isExternal
                                        ? (window.location.href = menu.premierFoundingPartner.link)
                                        : window.open(menu.premierFoundingPartner.link)
                                }}
                                ref={ref}
                                alt='load'
                                className='cursor-pointer w-[176px] h-[176px] object-contain'
                                src={menu.premierFoundingPartner.iconUrl}
                            />
                        )}
                    </div>
                    <div className={cn('flex justify-between items-center mt-4 lg:hidden', !!foundingPartner.length ? '' : 'hidden')}>
                        <Title className='font-semiboldFamily'>{t('landing.foundingPanter')}</Title>
                        <IconButtonChange
                            goToNextSlide={goToNextSlide}
                            goToPrevSlide={goToPrevSlide}
                            buttonColor={'#231448'}
                            borderColor={'border-secondary-midnightBlue'}
                            slideIndex={slideIndex}
                            length={menu.foundingPartner.foundingPartnerList.length}
                            num={num >= menu.foundingPartner.foundingPartnerList.length ? menu.foundingPartner.foundingPartnerList.length : num}
                            width='md:h-[42px] md:w-[42px] '
                        />
                    </div>
                    <Swiper
                        spaceBetween={10}
                        modules={[Navigation]}
                        onSwiper={(swiper) => {
                            setSwiper(swiper)
                        }}
                        onActiveIndexChange={(swiper) => {
                            if (swiper.activeIndex >= 0 && swiper.activeIndex <= menu.foundingPartner.foundingPartnerList.length - 1) {
                                setSlideIndex(swiper.activeIndex)
                            }
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 4,
                                spaceBetween: 24,
                            },
                            768: {
                                slidesPerView: 6,
                                spaceBetween: 24,
                            },
                            1440: {
                                slidesPerView: 8,
                                spaceBetween: 24,
                            },
                        }}
                        className='mySwiper flex-1'
                    >
                        {foundingPartner.map((img, index) => {
                            return (
                                <SwiperSlide
                                  key={index}
                                  className='lg:h-[84px] lg:w-[84px] xs:w-[120px] xs:h-[120px] w-[60px] h-[60px]'
                                >
                                    {
                                        <img
                                            onClick={() => {
                                                img.isExternal
                                                    ? (window.location.href = img.link)
                                                    : window.open(img.link)
                                            }}
                                            alt='load'
                                            src={img.iconUrl}
                                            className='cursor-pointer object-contain w-full h-full'
                                        />
                                    }
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </Wrap>
        </div>
    )
}

export default BrandSwiper
