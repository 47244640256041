import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { range, reverse } from 'ramda'

export const filterName = (val, lng, isNewLang = false) => {
    const valReturn = lng === 'en_US' ? val?.nameEn : lng === 'zh_CN' ? val?.nameCn : val?.nameZh
    const newValReturn = lng === 'en_US' ? val?.en_US : lng === 'zh_CN' ? val?.zh_CN : val?.zh_HK
    return isNewLang ? newValReturn : valReturn
}

export function encrypt(data) {
    // const secretKey = process.env.REACT_APP_SECRET_KEY
    // const key = CryptoJS.enc.Utf8.parse(secretKey)

    // const secretIV = '0000000000000000'
    // const iv = CryptoJS.enc.Hex.parse(secretIV)

    // const encrypted = CryptoJS.AES.encrypt(data, key, {
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    // })

    // // Encrypt
    // return encrypted.toString()
    return data
}

export function decryptAes(ciphertext) {
    // const secretKey = process.env.REACT_APP_SECRET_KEY
    // const key = CryptoJS.enc.Utf8.parse(secretKey)

    // const secretIV = '0000000000000000'
    // const iv = CryptoJS.enc.Hex.parse(secretIV)

    // const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7,
    // })

    // // Decrypt
    // return decrypted.toString(CryptoJS.enc.Utf8)
    return ciphertext
}

export const isImage = (url) => {
    const extensions = ['.mp4', '.MP4']

    for (let extension of extensions) {
        if (url?.endsWith(extension)) {
            return false
        }
    }

    return true
}

export const newIdArray = (arr) => {
    return arr.length > 0 ? arr.map((item) => item.id) : []
}

export const formatStartDateEndDate = (startDate, endDate, language) => {
    const formatModelStart = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
    const formatModelEnd = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
    const formatModelEndSameMonth = language === 'en_US' ? 'D MMM YYYY' : 'D日'
    const formatModelStartSameMonth = language === 'en_US' ? 'D' : 'YYYY年M月D'
    const formatModelEndSameYear = language === 'en_US' ? 'D MMM YYYY' : 'M月D日'
    const formatModelStartSameYear = language === 'en_US' ? 'D MMM' : 'YYYY年M月D日'
    const formatStartDate = startDate ? dayjs(startDate).format(formatModelStart) : null
    const formatEndDate = endDate ? dayjs(endDate).format(formatModelEnd) : null
    const isSameMonth = dayjs(startDate).format('YYYY M') === dayjs(endDate).format('YYYY M')
    const isSameDay = dayjs(startDate).format('YYYY M D') === dayjs(endDate).format('YYYY M D')
    const isSameYear =
        dayjs(startDate).format('YYYY') === dayjs(endDate).format('YYYY') &&
        dayjs(startDate).format('YYYY M') !== dayjs(endDate).format('YYYY M')
    const gap = language === 'en_US' ? ' - ' : '至'
    return startDate && endDate && isSameMonth && !isSameDay
        ? dayjs(startDate).format(formatModelStartSameMonth) + gap + dayjs(endDate).format(formatModelEndSameMonth)
        : startDate && endDate && isSameYear && !isSameMonth
        ? dayjs(startDate).format(formatModelStartSameYear) + gap + dayjs(endDate).format(formatModelEndSameYear)
        : startDate && endDate && isSameDay
        ? formatStartDate
        : startDate && endDate
        ? formatStartDate + gap + formatEndDate
        : null
}

export const formatStartTimeEndTime = (time, language) => {
    let [hour, min] = time.split(':').map(Number)

    let status
    if (language === 'en_US') {
        status = hour >= 12 ? 'PM' : 'AM'
    } else {
        status = hour >= 12 ? '下午' : '上午'
    }
    hour = hour % 12 || 12
    return hour.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0') + ' ' + status
}

export const dateRangeFormat = (dateRange = [], language) => {
    const formatModelStart = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
    return dateRange.length > 1 &&
        dayjs(dateRange[0].eventStartDateTime).format('D MMM YYYY') !==
            dayjs(dateRange[dateRange.length - 1].eventEndDateTime).format('D MMM YYYY')
        ? formatStartDateEndDate(
              dateRange[0].eventStartDateTime,
              dateRange[dateRange.length - 1].eventEndDateTime,
              language,
          )
        : dateRange.length > 1 &&
          dayjs(dateRange[0].eventStartDateTime).format('D MMM YYYY') ===
              dayjs(dateRange[dateRange.length - 1].eventEndDateTime).format('D MMM YYYY')
        ? dayjs(dateRange[0].eventStartDateTime).format(formatModelStart)
        : dateRange.length === 1
        ? formatStartDateEndDate(dateRange[0].eventStartDateTime, dateRange[0].eventEndDateTime, language)
        : null
}

// 1924 - 2009
export const yearList = reverse(range(dayjs().year() - 100, dayjs().year() - 15 + 1).map((v) => String(v)))

export const monthList = [
    { value: '01', label: 'month.Jan' },
    { value: '02', label: 'month.Feb' },
    { value: '03', label: 'month.Mar' },
    { value: '04', label: 'month.Apr' },
    { value: '05', label: 'month.May' },
    { value: '06', label: 'month.Jun' },
    { value: '07', label: 'month.Jul' },
    { value: '08', label: 'month.Aug' },
    { value: '09', label: 'month.Sep' },
    { value: '10', label: 'month.Oct' },
    { value: '11', label: 'month.Nov' },
    { value: '12', label: 'month.Dec' },
]

export const datesSelect = () => {
    return [
        { about: 'eventPage.allDate', val: null },
        { about: 'eventPage.today', val: 'today' },
        { about: 'eventPage.tomorrow', val: 'tomorrow' },
        { about: 'eventPage.thisWeek', val: 'thisWeek' },
        { about: 'eventPage.nextWeek', val: 'nextWeek' },
        // { about: 'eventPage.thisMonth', val: 'thisMonth' },
        { about: 'eventPage.nextMonth', val: 'nextMonth' },
        { about: 'eventPage.flexibleDates', val: 'flexibleDate' },
    ]
}

export const fileType = (url) => {
    return url.split('.').pop().toLowerCase()
}

export const ensureHttps = (url) => {
    // 检查 URL 是否以 "http://" 或 "https://" 开头
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // 如果没有，添加 "https://" 前缀
        url = 'https://' + url
    }
    return url
}

export const displayEmail = (emailId) => {
    const [firstPart, secondPart] = emailId.split('@')
    const firstChars = firstPart.length > 4 ? firstPart.slice(0, 4) : firstPart.slice(0, 1)

    return `${firstChars}****@${secondPart}`
}

export const IASMError = ['D50001', 'D40001', 'D40000', 'D40003', 'D50002', 'D50003', 'D40004', 'D40002']
export const notAvailableErrorCode = {
    event: '14000',
    newsroom: '14001',
    programme: '14002',
    shop: '14003',
    dine: '14004',
    venue: '14005',
    promotion: '14006',
    facility: '14007',
    carpark: '14008',
}

export const APIWhitelist = [
    '/vbs/getActivitiesStatus',
    '/vbs/getFacilitiesStatus',
    '/vbs/myBooking',
    '/vbs/myProgramme',
]

export const iasmLangObj = {
    en_US: 'en-US',
    zh_HK: 'zh-HK',
    zh_CN: 'zh-CN',
}

export const getNameByCode = (code = '', arr = []) => {
    const result = arr.find((item) => item.code === code)

    return result ? result.name : null
}

export const filterDownLoadAppIcon = (item, language) => {
    return language === 'en_US' ? item.iconUrl : language === 'zh_HK' ? item.iconUrlZh : item.iconUrlCn
}

export const navigateLink = (navigate, newBrowser, webLinkUrl) => {
    newBrowser ? window.open(webLinkUrl) : navigate(webLinkUrl.replace(process.env.REACT_APP_WEBSITE, ''))
}
