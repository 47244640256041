import React, { useMemo } from 'react'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'
import { cond, equals, always } from 'ramda'

import { dateRangeFormat } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
import BuyNowBtn from 'page/FacilitiesBooking/components/BuyNowBtn'
import VenueComponent from 'components/detailComponents/VenueComponent'

const Title = tw.div`w-[116px] text-sm md:text-base`

function TopIntro({ btnDatas = null, items }) {
    const {
        programmeName,
        dateRange,
        ageFrom,
        ageTo,
        coaches,
        language,
        specialRemarkDescription,
        specialRemarkTitle,
        venues,
        bottomRemarkTitle,
        bottomRemarkDescription,
        ballotResult,
        programmeTypeNameCn,
        programmeTypeNameEn,
        programmeTypeNameZh,
    } = items

    const {
        t,
        i18n: { language: i18Lang },
    } = useTranslation()

    const isPC = useMediaQuery('(min-width: 1024px)')

    const datesFormat = dateRange && dateRangeFormat(dateRange, i18Lang)
    const buttonStatus = {
        type: btnDatas?.enrlMethod || '',
        ctaButtonUrl: btnDatas?.actionLink || '',
        ctaButtonStatus: btnDatas?.actionLink && btnDatas?.actionLink !== 'disabled',
        bottonRemarkTitle: bottomRemarkTitle,
        bottonRemarkDescription: bottomRemarkDescription,
        ballotLink: ballotResult,
    }

    const categoryName = useMemo(() => {
        return cond([
            [equals('en_US'), always(programmeTypeNameEn)],
            [equals('zh_HK'), always(programmeTypeNameZh)],
            [equals('zh_CN'), always(programmeTypeNameCn)],
        ])(i18Lang)
    }, [i18Lang])

    return (
        <div className='w-full flex gap-7'>
            <div className=' w-full py-6 max-w-[800px] md:pt-8 md:pb-12'>
                <NavigationPoint firstNav={t('programme.title')} lastNav={programmeName} firstNavLink={'/programmes'} />
                <h1 className='text-h3 w-full text-secondary-midnightBlue py-8'>{programmeName}</h1>
                {(specialRemarkTitle || specialRemarkDescription) && (
                    <ExclusiveOffer title={specialRemarkTitle} about={specialRemarkDescription} />
                )}
                <div className='space-y-4'>
                    {datesFormat && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('eventPage.date')}</Title>
                            <div className='text-body1'>{datesFormat}</div>
                        </div>
                    )}
                    {dateRange && dateRange.length > 0 && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('eventPage.time')}</Title>
                            <div>
                                {dateRange.map((item, index) => {
                                    return (
                                        <div key={index} className='flex-1 text-body1'>
                                            {dayjs(item.eventStartDateTime).format('HH:mm') +
                                                ' - ' +
                                                dayjs(item.eventEndDateTime).format('HH:mm')}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {venues?.length > 0 &&
                        venues.map((item, index) => {
                            return <VenueComponent venue={item} key={index} />
                        })}

                    {ageFrom + '' !== 'null' && ageTo + '' !== 'null' && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('programme.age')}</Title>
                            <div className='flex-1 pr-2 text-body1 break-words overflow-hidden cursor-pointer'>
                                {ageFrom + '-' + ageTo}
                            </div>
                        </div>
                    )}
                    {coaches && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('programme.coach')}</Title>
                            <div className='flex-1 pr-2 text-body1 break-words overflow-hidden cursor-pointer'>
                                {coaches}
                            </div>
                        </div>
                    )}
                    {language && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('programme.language')}</Title>
                            <div className='flex-1 pr-2 text-body1 break-words overflow-hidden cursor-pointer'>
                                {language}
                            </div>
                        </div>
                    )}
                    {categoryName && (
                        <div className='flex gap-[10px]'>
                            <Title className='text-body2'>{t('eventPage.type')}</Title>
                            <div className='flex flex-1 flex-wrap'>{categoryName}</div>
                        </div>
                    )}
                </div>
            </div>
            {btnDatas && (
                <>
                    {isPC ? (
                        <BuyNowBtn times={dateRange} items={buttonStatus} datesFormat={datesFormat} />
                    ) : (
                        createPortal(
                            <BuyNowBtn times={dateRange} items={buttonStatus} datesFormat={datesFormat} />,
                            document.getElementById('main-container'),
                        )
                    )}
                </>
            )}
        </div>
    )
}

export default TopIntro
