import React, { useEffect, useState } from 'react'
import { Tab, Box, Tabs, Button, tabsClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'

import SearchInput from 'components/SearchInput'
import All from 'page/ConnectWithKTSP/FAQ/conponents/All'
import CommonLabels from 'page/ConnectWithKTSP/FAQ/conponents/CommonLabels'
import Loading from 'components/Loading'
import { information } from 'service/informationService'
const Wrap = tw.div`bg-white shadow-[0px 0px 10px rgb(0 0 0 / 8%)] mt-6 px-5 py-2  lg:mt-10 lg:px-10 lg:py-5`

export default function TabsChange({ tabsList = [] }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const [value, setValue] = useState(0)
    const [tabValue, setTabValue] = useState(0)
    const [datas, setDatas] = useState([])

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }
    // const tabsTitles = [t('FAQ.all'), t('FAQ.function'), t('FAQ.account')]
    const tabsTitles = [{ id: 0, name: t('FAQ.all') }, ...tabsList]

    const getFaqDatas = useMutation({
        mutationFn: information.getFootFaqSearch,
        onSuccess: setDatas,
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        getFaqDatas.mutate({ keyword: searchVal.trim(), lang: language, tagId: value })
    }, [language])

    return (
        <div className='pt-[48px] pb-10 bg-container'>
            <div className='border-solid border-b-[1px] border-secondary-darkGery flex flex-col-reverse gap-4 md:flex-row md:justify-between '>
                <Tabs
                    textColor='secondary'
                    indicatorColor='secondary'
                    TabIndicatorProps={{
                        sx: {
                            height: '4px',
                            borderTopRightRadius: '4px',
                            backgroundColor: 'secondary',
                        },
                    }}
                    value={tabValue}
                    scrollButtons={false}
                    variant='scrollable'
                    onChange={handleChange}
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                    aria-label='scrollable auto tabs example'
                >
                    {tabsTitles.map((tab, index) => {
                        return (
                            <Tab
                                sx={{
                                    fontWeight: tabValue === index ? 600 : 500,
                                    textTransform: 'none',
                                    alignItems: 'flex-start',
                                    paddingLeft: 0,
                                    marginRight: 2,
                                    minWidth: '0px',
                                }}
                                className={'' + (index === 0 && 'pl-0 ')}
                                key={index}
                                label={
                                    <Box sx={{ fontSize: 16, fontWeight: 600, textTransform: 'none' }}>{tab.name}</Box>
                                }
                                onClick={() => {
                                    setValue(tab.id)
                                    getFaqDatas.mutate({ keyword: searchVal.trim(), lang: language, tagId: tab.id })
                                }}
                            />
                        )
                    })}
                </Tabs>
                <SearchInput
                    setIsSearch={setIsSearch}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    onBlurFun={() =>
                        !getFaqDatas.isPending &&
                        getFaqDatas.mutate({ keyword: searchVal, lang: language, tagId: value })
                    }
                />
            </div>

            {getFaqDatas.isPending && <Loading />}
            {isSearch && (
                <>
                    {tabValue !== 0 && datas.length > 0 && <CommonLabels items={datas} title={datas[0].tagName} />}
                    {tabValue === 0 && <All searchVal={searchVal} items={datas} />}
                </>
            )}

            <Wrap>
                <div className='flex justify-between items-center gap-1'>
                    <div className='space-y-2'>
                        <div className='text-xl text-secondary-midnightBlue font-semiboldFamily'>
                            {t('FAQ.contactUs')}
                        </div>
                        <div className='text-xs font-medium'>{t('FAQ.searchTip')}</div>
                    </div>
                    <Button
                        onClick={() => navigate('/contactUs')}
                        className='mt-2 text-white normal-case bg-website-gradation h-8 text-sm font-semiboldFamily px-4 py-1 min-w-fit lg:h-12  lg:min-w-[270px]'
                        variant='contained'
                        size='large'
                    >
                        {t('FAQ.contactUsBtn')}
                    </Button>
                </div>
            </Wrap>
        </div>
    )
}
