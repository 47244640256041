import React, { useEffect, useState } from 'react'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import Loading from 'components/Loading'
import { information } from 'service/informationService'
import AllTransport from 'page/Transportation/components/AllTransport'
import MTR from 'page/Transportation/components/MTR'
import Bus from 'page/Transportation/components/Bus'
import Minibus from 'page/Transportation/components/Minibus'
import Taxi from 'page/Transportation/components/Taxi'
import Car from 'page/Transportation/components/Car'
import Ferry from 'page/Transportation/components/Ferry'
import CrossBoundary from 'page/Transportation/components/CrossBoundary'
import ButtonsTab from 'components/ButtonsTab'
function GuideDetail() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [type, setType] = useState('all')
    const [link, setLink] = useState('')
    const btnVals = [
        { text: t('transport.allTransport'), val: 'all' },
        { text: t('transport.mtr'), val: 'MTR' },
        { text: t('transport.bus'), val: 'bus' },
        // { text: t('transport.minibus'), val: 'minibus' },
        // { text: t('transport.car'), val: 'car' },
        { text: t('transport.ferry'), val: 'ferry' },
        { text: t('transport.taxi'), val: 'taxi' },
        // { text: t('transport.crossBoundary'), val: 'crossBoundary' },
    ]
    const downloadTransport = useMutation({
        mutationFn: information.downloadTransport,
        onSuccess: (res) => {
            setLink(res[0].documentUrl)
        },
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        downloadTransport.mutate({ lang: language })
    }, [language])

    return (
        <section>
            <div className='pt-16 bg-container'>
                <div className='flex justify-between items-center font-semiboldFamily text-secondary-midnightBlue'>
                    <div className='text-[22px] leading-[22px] md:text-4xl'>{t('transport.howToGettingHere')}</div>
                    {/*{link && (*/}
                    {/*    <a*/}
                    {/*        download='guide.pdf'*/}
                    {/*        href={link}*/}
                    {/*        className='text-sm flex items-center cursor-pointer active:opacity-50'*/}
                    {/*    >*/}
                    {/*        {t('transport.downloadGuide')}*/}
                    {/*        <FileDownloadOutlinedIcon />*/}
                    {/*    </a>*/}
                    {/*)}*/}
                </div>
            </div>
            <ButtonsTab
                list={btnVals.map((btnVal) => ({ selected: type === btnVal.val, ...btnVal }))}
                onItemClick={(item) => {
                    setType(item.val)
                }}
            />
            <div className='pb-16 md:pb-[72px] bg-container'>
                <div style={{ display: type === 'all' ? 'block' : 'none' }}>
                    <AllTransport />
                </div>
                <div style={{ display: type === 'MTR' ? 'block' : 'none' }}>
                    <MTR />
                </div>
                <div style={{ display: type === 'bus' ? 'block' : 'none' }}>
                    <Bus />
                </div>
                <div style={{ display: type === 'minibus' ? 'block' : 'none' }}>
                    <Minibus />
                </div>
                <div style={{ display: type === 'taxi' ? 'block' : 'none' }}>
                    <Taxi />
                </div>
                <div style={{ display: type === 'car' ? 'block' : 'none' }}>
                    <Car />
                </div>
                <div style={{ display: type === 'ferry' ? 'block' : 'none' }}>
                    <Ferry />
                </div>
                <div style={{ display: type === 'crossBoundary' ? 'block' : 'none' }}>
                    <CrossBoundary />
                </div>
            </div>
        </section>
    )
}

export default GuideDetail
