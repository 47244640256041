const busList = [
    {
        routeNo: '5',
        EN: 'Fu Shan Estate - Star Ferry',
        TC: '富山邨 - 尖沙咀碼頭',
        SC: '富山邨 - 尖沙咀码头',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 1,
    },
    {
        routeNo: '5',
        EN: 'Star Ferry - Fu Shan Estate',
        TC: '尖沙咀碼頭 - 富山邨',
        SC: '尖沙咀码头 - 富山邨',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 2,
    },
    {
        routeNo: '9',
        EN: 'Choi Fook - Tsim Sha Tsui East (Mody Road)',
        TC: '彩福 - 尖沙咀東（麼地道）',
        SC: '彩福 - 尖沙咀东（么地道）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 3,
    },
    {
        routeNo: '9',
        EN: 'Tsim Sha Tsui East (Mody Road) - Choi Fook',
        TC: '尖沙咀東（麼地道）- 彩福',
        SC: '尖沙咀东（么地道）- 彩福',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 4,
    },
    {
        routeNo: '11',
        EN: 'Diamond Hill Station - Kowloon Station',
        TC: '鑽石山站 - 九龍站',
        SC: '钻石山站 - 九龙站',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 5,
    },
    {
        routeNo: '11',
        EN: 'Kowloon Station - Diamond Hill Station',
        TC: '九龍站 - 鑽石山站',
        SC: '九龙站 - 钻石山站',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 6,
    },
    {
        routeNo: '14',
        EN: 'China Ferry Terminal - Lei Yue Mun Estate',
        TC: '中港碼頭 - 鯉魚門邨',
        SC: '中港码头 - 鲤鱼门邨',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 7,
    },
    {
        routeNo: '14',
        EN: 'Lei Yue Mun Estate - China Ferry Terminal',
        TC: '鯉魚門邨 - 中港碼頭',
        SC: '鲤鱼门邨 - 中港码头',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 8,
    },
    {
        routeNo: '15',
        EN: 'Hung Hom (Hung Luen Road) - Ping Tin',
        TC: '紅磡（紅鸞道）- 平田',
        SC: '红磡（红鸾道）- 平田',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 9,
    },
    {
        routeNo: '15',
        EN: 'Ping Tin - Hung Hom (Hung Luen Road)',
        TC: '平田 - 紅磡（紅鸞道）',
        SC: '平田 - 红磡（红鸾道）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 10,
    },
    {
        routeNo: '16',
        EN: 'Lam Tin (Kwong Tin Estate) - Mong Kok (Park Avenue)',
        TC: '藍田（廣田邨）- 旺角（柏景灣）',
        SC: '蓝田（广田邨）- 旺角（柏景湾）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 11,
    },
    {
        routeNo: '16',
        EN: 'Mong Kok (Park Avenue) - Lam Tin (Kwong Tin Estate)',
        TC: '旺角（柏景灣）- 藍田（廣田邨）',
        SC: '旺角（柏景湾）- 蓝田（广田邨）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 12,
    },
    {
        routeNo: '17',
        EN: 'Ho Man Tin (Oi Man Estate) - Kwun Tong (Yue Man Square)',
        TC: '何文田（愛民邨）- 觀塘（裕民坊）',
        SC: '何文田（爱民邨）- 观塘（裕民坊）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 13,
    },
    {
        routeNo: '17',
        EN: 'Kwun Tong (Yue Man Square) - Ho Man Tin (Oi Man Estate)',
        TC: '觀塘（裕民坊）- 何文田（愛民邨）',
        SC: '观塘（裕民坊）- 何文田（爱民邨）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 14,
    },
    {
        routeNo: '20',
        EN: 'Cheung Sha Wan (Hoi Tat) - Kai Tak (Muk On Street)',
        TC: '長沙灣（海達）- 啟德（沐安街）',
        SC: '长沙湾（海达）- 启德（沐安街）',
        Remark: 'Citybus',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 15,
    },
    {
        routeNo: '20',
        EN: 'Kai Tak (Muk On Street) - Cheung Sha Wan (Hoi Tat)',
        TC: '啟德（沐安街）- 長沙灣（海達）',
        SC: '启德（沐安街）- 长沙湾（海达）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 16,
    },
    {
        routeNo: '21',
        EN: 'Choi Wan - Hung Hom Station',
        TC: '彩雲 - 紅磡站',
        SC: '彩云 - 红磡站',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop3: 'N/A',
        id: 17,
    },
    {
        routeNo: '21',
        EN: 'Hung Hom Station - Choi Wan',
        TC: '紅磡站 - 彩雲',
        SC: '红磡站 - 彩云',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 18,
    },
    {
        routeNo: '22',
        EN: 'Kai Tak Cruise Terminal - Kowloon Tong (Festival Walk)',
        TC: '啟德郵輪碼頭 - 九龍塘（又一城）',
        SC: '启德邮轮码头 - 九龙塘（又一城）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 19,
    },
    {
        routeNo: '22',
        EN: 'Kowloon Tong (Festival Walk) - Kai Tak Cruise Terminal',
        TC: '九龍塘（又一城）- 啟德郵輪碼頭',
        SC: '九龙塘（又一城）- 启德邮轮码头',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 20,
    },
    {
        routeNo: '24',
        EN: 'Kai Yip - Mong Kok (Circular)',
        TC: '啟業 - 旺角（循環線）',
        SC: '启业 - 旺角（循环线）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 21,
    },
    {
        routeNo: '26',
        EN: 'Shun Tin - Tsim Sha Tsui East',
        TC: '順天 - 尖沙咀東',
        SC: '顺天 - 尖沙咀东',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 22,
    },
    {
        routeNo: '26',
        EN: 'Tsim Sha Tsui East - Shun Tin',
        TC: '尖沙咀東 - 順天',
        SC: '尖沙咀东 - 顺天',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 23,
    },
    {
        routeNo: '27',
        EN: 'Shun Tin - Mong Kok (Circular)',
        TC: '順天 - 旺角（循環線）',
        SC: '顺天 - 旺角（循环线）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 24,
    },
    {
        routeNo: '28',
        EN: 'Star Ferry - Lok Wah',
        TC: '尖沙咀碼頭 - 樂華',
        SC: '尖沙咀码头 - 乐华',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 25,
    },
    {
        routeNo: '42',
        EN: 'Shun Lee - Tsing Yi (Cheung Hong Estate)',
        TC: '順利 - 青衣（長康邨）',
        SC: '顺利 - 青衣（长康邨）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 26,
    },
    {
        routeNo: '42',
        EN: 'Tsing Yi (Cheung Hong Estate) - Shun Lee',
        TC: '青衣（長康邨）- 順利',
        SC: '青衣（长康邨）- 顺利',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 27,
    },
    {
        routeNo: '45',
        EN: 'Kwai Chung (Lai Yiu Estate) - Kowloon City Ferry',
        TC: '葵涌（麗瑤邨）- 九龍城碼頭',
        SC: '葵涌（丽瑶邨）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 28,
    },
    {
        routeNo: '85',
        EN: 'Fo Tan Chun Yeung Estate - Kowloon City Ferry',
        TC: '火炭駿洋邨 - 九龍城碼頭',
        SC: '火炭骏洋邨 - 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 29,
    },
    {
        routeNo: '95',
        EN: 'Kowloon Station - Tsui Lam',
        TC: '九龍站 - 翠林',
        SC: '九龙站 - 翠林',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 30,
    },
    {
        routeNo: '95',
        EN: 'Tsui Lam - Kowloon Station',
        TC: '翠林 - 九龍站',
        SC: '翠林 - 九龙站',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 31,
    },
    {
        routeNo: '101',
        EN: 'Kennedy Town - Kwun Tong (Yue Man Square)',
        TC: '堅尼地城 - 觀塘（裕民坊）',
        SC: '坚尼地城 - 观塘（裕民坊）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 32,
    },
    {
        routeNo: '101',
        EN: 'Kwun Tong (Yue Man Square) - Kennedy Town',
        TC: '觀塘（裕民坊）- 堅尼地城',
        SC: '观塘（裕民坊）- 坚尼地城',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 33,
    },
    {
        routeNo: '106',
        EN: 'Siu Sai Wan (Island Resort) - Wong Tai Sin',
        TC: '小西灣（藍灣半島）- 黃大仙',
        SC: '小西湾（蓝湾半岛）- 黄大仙',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 34,
    },
    {
        routeNo: '106',
        EN: 'Wong Tai Sin - Siu Sai Wan (Island Resort)',
        TC: '黃大仙 - 小西灣（藍灣半島）',
        SC: '黄大仙 - 小西湾（蓝湾半岛）',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop3: 'N/A',
        id: 35,
    },
    {
        routeNo: '107',
        EN: 'Kowloon Bay - Wan Kwai',
        TC: '九龍灣 - 華貴',
        SC: '九龙湾 - 华贵',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 36,
    },
    {
        routeNo: '107',
        EN: 'Wah Kwai - Kowloon Bay',
        TC: '華貴 - 九龍灣',
        SC: '华贵 - 九龙湾',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 37,
    },
    {
        routeNo: '108',
        EN: 'Braemar Hill - Kai Yip',
        TC: '寶馬山 - 啟業',
        SC: '宝马山 - 启业',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 38,
    },
    {
        routeNo: '108',
        EN: 'Kai Yip - Braemar Hill',
        TC: '啟業 - 寶馬山',
        SC: '启业 - 宝马山',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 39,
    },
    {
        routeNo: '111',
        EN: 'Central (Macau Ferry) - Ping Shek/ Choi Hung Station',
        TC: '中環（港澳碼頭）- 坪石／彩虹站',
        SC: '中环（港澳码头）- 坪石／彩虹站',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 40,
    },
    {
        routeNo: '111',
        EN: 'Ping Shek/ Choi Hung Station - Central (Macau Ferry)',
        TC: '坪石／彩虹站 - 中環（港澳碼頭）',
        SC: '坪石／彩虹站 - 中环（港澳码头）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 41,
    },
    {
        routeNo: '115',
        EN: 'Central (Macau Ferry) - Kowloon City Ferry',
        TC: '中環（港澳碼頭）- 九龍城碼頭',
        SC: '中环（港澳码头）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 42,
    },
    {
        routeNo: '116',
        EN: 'Quarry Bay (Yau Man Street) - Tsz Wan Shan (Central)',
        TC: '鰂魚涌（祐民街）- 慈雲山（中）',
        SC: '鲗鱼涌（佑民街）- 慈云山（中）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 43,
    },
    {
        routeNo: '116',
        EN: 'Tsz Wan Shan (Central) - Quarry Bay (Yau Man Street)',
        TC: '慈雲山（中）- 鰂魚涌（祐民街）',
        SC: '慈云山（中）- 鲗鱼涌（佑民街）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 44,
    },
    {
        routeNo: '297',
        EN: 'Hung Hom (Hung Luen Road) - Po Lam',
        TC: '紅磡（紅鸞道）- 寶琳',
        SC: '红磡（红鸾道）- 宝琳',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 45,
    },
    {
        routeNo: '608',
        EN: 'Kowloon City (Shing Tak Street) - Shau Kei Wan',
        TC: '九龍城（盛德街）- 筲箕灣',
        SC: '九龙城（盛德街）- 筲箕湾',
        Remark: 'Citybus',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 46,
    },
    {
        routeNo: '793',
        EN: 'So Uk - Tseung Kwan O Industrial Estate',
        TC: '蘇屋 - 將軍澳工業邨',
        SC: '苏屋 - 将军澳工业邨',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 47,
    },
    {
        routeNo: '793',
        EN: 'Tseung Kwan O Industrial Estate - So Uk',
        TC: '將軍澳工業邨 - 蘇屋',
        SC: '将军澳工业邨 - 苏屋',
        Remark: 'Citybus',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop3: 'N/A',
        id: 48,
    },
    {
        routeNo: '11B',
        EN: 'Kwan Tong (Tsui Ping Road) - Kowloon City Ferry',
        TC: '觀塘（翠屏道）- 九龍城碼頭',
        SC: '观塘（翠屏道）- 九龙城码头',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City Ferry Bus Terminus',
        BusStop3: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        id: 55,
    },
    {
        routeNo: '11D',
        EN: 'Kwun Tong Ferry - Lok Fu',
        TC: '觀塘碼頭 - 樂富',
        SC: '观塘码头 - 乐富',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 56,
    },
    {
        routeNo: '11D',
        EN: 'Lok Fu - Kwun Tong Ferry',
        TC: '樂富 - 觀塘碼頭',
        SC: '乐富 - 观塘码头',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 57,
    },
    {
        routeNo: '11K',
        EN: 'Chuk Yuen Estate - Hung Hom Station',
        TC: '竹園邨 - 紅磡站',
        SC: '竹园邨 - 红磡站',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 58,
    },
    {
        routeNo: '11K',
        EN: 'Hung Hom Station - Chuk Yuen Estate',
        TC: '紅磡站 - 竹園邨',
        SC: '红磡站 - 竹园邨',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 59,
    },
    {
        routeNo: '11X',
        EN: 'Hung Hom Station - On Tai (North)',
        TC: '紅磡站 - 安泰（北）',
        SC: '红磡站 - 安泰（北）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 60,
    },
    {
        routeNo: '12A',
        EN: 'Cheung Sha Wan (Hoi Tat Estate) - Whampoa Garden',
        TC: '長沙灣（海達邨）- 黃埔花園',
        SC: '长沙湾（海达邨）- 黄埔花园',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 61,
    },
    {
        routeNo: '12A',
        EN: 'Whampoa Garden - Cheung Sha Wan (Hoi Tat Estate)',
        TC: '黃埔花園 - 長沙灣（海達邨）',
        SC: '黄埔花园 - 长沙湾（海达邨）',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 62,
    },
    {
        routeNo: '1A',
        EN: 'Sau Mau Ping (Central) - Star Ferry',
        TC: '中秀茂坪 - 尖沙咀碼頭',
        SC: '中秀茂坪 - 尖沙咀码头',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 67,
    },
    {
        routeNo: '1A',
        EN: 'Star Ferry - Sau Mau Ping (Central)',
        TC: '尖沙咀碼頭 - 中秀茂坪',
        SC: '尖沙咀码头 - 中秀茂坪',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 68,
    },
    {
        routeNo: '203E',
        EN: 'Choi Hung - Kowloon Station',
        TC: '彩虹 - 九龍站',
        SC: '彩虹 - 九龙站',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 69,
    },
    {
        routeNo: '203E',
        EN: 'Kowloon Station - Choi Hung',
        TC: '九龍站 - 彩虹',
        SC: '九龙站 - 彩虹',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 70,
    },
    {
        routeNo: '20A',
        EN: 'High Speed Rail West Kowloon Station - Kai Tak Cruise Terminal (via Mong Kok)',
        TC: '高鐵西九龍站 - 啟德郵輪碼頭（經旺角）',
        SC: '高铁西九龙站 - 启德邮轮码头（经旺角）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 71,
    },
    {
        routeNo: '20A',
        EN: 'Kai Tak Cruise Terminal - High Speed Rail West Kowloon Station (via Mong Kok)',
        TC: '啟德郵輪碼頭 - 高鐵西九龍站（經旺角）',
        SC: '启德邮轮码头 - 高铁西九龙站（经旺角）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 72,
    },
    {
        routeNo: '213D',
        EN: 'Sau Mau Ping (Central) - Mong Kok (Circular)',
        TC: '中秀茂坪 - 旺角（循環線）',
        SC: '中秀茂坪 - 旺角（循环线）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 73,
    },
    {
        routeNo: '22M',
        EN: 'Kai Tak Cruise Terminal - To Kwa Wan (Circular)',
        TC: '啟德郵輪碼頭 - 土瓜灣（循環線）',
        SC: '启德邮轮码头 - 土瓜湾（循环线）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 74,
    },
    {
        routeNo: '241X',
        EN: 'Tsing Yi (Cheung Ching Estate) - Kowloon City Ferry',
        TC: '青衣（長青邨）- 九龍城碼頭',
        SC: '青衣（长青邨）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 75,
    },
    {
        routeNo: '296C',
        EN: 'Cheung Sha Wan (Hoi Ying Estate) - Sheung Tak',
        TC: '長沙灣（海盈邨）- 尚德',
        SC: '长沙湾（海盈邨）- 尚德',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 81,
    },
    {
        routeNo: '2A',
        EN: 'Lok Wah - Mei Foo',
        TC: '樂華 - 美孚',
        SC: '乐华 - 美孚',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 85,
    },
    {
        routeNo: '2A',
        EN: 'Mei Foo - Lok Wah',
        TC: '美孚 - 樂華',
        SC: '美孚 - 乐华',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 86,
    },
    {
        routeNo: '2E',
        EN: 'Pak Tin (North) - Kowloon City Ferry',
        TC: '白田（北）- 九龍城碼頭',
        SC: '白田（北）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 87,
    },
    {
        routeNo: '3B',
        EN: 'Hung Hom (Hung Luen Road) - Tsz Wan Shan (Central)',
        TC: '紅磡（紅鸞道）- 慈雲山（中）',
        SC: '红磡（红鸾道）- 慈云山（中）',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 88,
    },
    {
        routeNo: '3B',
        EN: 'Tsz Wan Shan (Central) - Hung Hom (Hung Luen Road)',
        TC: '慈雲山（中）- 紅磡（紅鸞道）',
        SC: '慈云山（中）- 红磡（红鸾道）',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop3: 'N/A',
        id: 89,
    },
    {
        routeNo: '5A',
        EN: 'Kai Tak (Kai Ching Estate) - Star Ferry',
        TC: '啟德（啟晴邨）- 尖沙咀碼頭',
        SC: '启德（启晴邨）- 尖沙咀码头',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 90,
    },
    {
        routeNo: '5A',
        EN: 'Star Ferry - Kai Tak (Kai Ching Estate)',
        TC: '尖沙咀碼頭 - 啟德（啟晴邨）',
        SC: '尖沙咀码头 - 启德（启晴邨）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 91,
    },
    {
        routeNo: '5C',
        EN: 'Star Ferry - Tsz Wan Shan (Central)',
        TC: '尖沙咀碼頭 - 慈雲山（中）',
        SC: '尖沙咀码头 - 慈云山（中）',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 92,
    },
    {
        routeNo: '5C',
        EN: 'Tsz Wan Shan (Central) - Star Ferry',
        TC: '慈雲山（中）- 尖沙咀碼頭',
        SC: '慈云山（中）- 尖沙咀码头',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop3: 'N/A',
        id: 93,
    },
    {
        routeNo: '61X',
        EN: 'Tuen Mun Central - Kowloon City Ferry',
        TC: '屯門市中心 - 九龍城碼頭',
        SC: '屯门市中心 - 九龙城码头',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City Ferry Bus Terminus',
        BusStop3: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        id: 95,
    },
    {
        routeNo: '6C',
        EN: 'Mei Foo - Kowloon City Ferry',
        TC: '美孚 - 九龍城碼頭',
        SC: '美孚 - 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 96,
    },
    {
        routeNo: '6D',
        EN: 'Mei Foo - Ngau Tau Kok',
        TC: '美孚 - 牛頭角',
        SC: '美孚 - 牛头角',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 97,
    },
    {
        routeNo: '6D',
        EN: 'Ngau Tau Kok - Mei Foo',
        TC: '牛頭角 - 美孚',
        SC: '牛头角 - 美孚',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 98,
    },
    {
        routeNo: '6F',
        EN: 'Cheung Sha Wan (Lai Kok Estate) - Kowloon City Ferry',
        TC: '長沙灣（麗閣邨）- 九龍城碼頭',
        SC: '长沙湾（丽阁邨）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 100,
    },
    {
        routeNo: '75X',
        EN: 'Tai Po (Fu Shin) - Kowloon City Ferry',
        TC: '大埔（富善）- 九龍城碼頭',
        SC: '大埔（富善）- 九龙城码头',
        Remark: '',
        BusStop1: 'Kowloon City Ferry Bus Terminus',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 103,
    },
    {
        routeNo: '796X',
        EN: 'Tseung Kwan O Industrial Estate - Tsim Sha Tsui (East)',
        TC: '將軍澳工業邨 - 尖沙咀（東）',
        SC: '将军澳工业邨 - 尖沙咀（东）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 104,
    },
    {
        routeNo: '796X',
        EN: 'Tsim Sha Tsui (East) - Tseung Kwan O Industrial Estate/ Tseung Kwan O Station',
        TC: '尖沙咀（東）- 將軍澳工業邨／將軍澳站',
        SC: '尖沙咀（东）- 将军澳工业邨／将军澳站',
        Remark: 'Citybus',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 105,
    },
    {
        routeNo: '85A',
        EN: 'Kwong Yuen - Kowloon City Ferry',
        TC: '廣源 - 九龍城碼頭',
        SC: '广源 - 九龙城码头',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City Ferry Bus Terminus',
        BusStop3: 'N/A',
        id: 106,
    },
    {
        routeNo: '85X',
        EN: 'Hung Hom (Hung Luen Road) - Ma On Shan Town Centre',
        TC: '紅磡（紅鸞道）- 馬鞍山市中心',
        SC: '红磡（红鸾道）- 马鞍山市中心',
        Remark: '',
        BusStop1: 'China Gas Company',
        BusStop2: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop3: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        id: 111,
    },
    {
        routeNo: '85X',
        EN: 'Ma On Shan Town Centre - Hung Hom (Hung Luen Road)',
        TC: '馬鞍山市中心 - 紅磡（紅鸞道）',
        SC: '马鞍山市中心 - 红磡（红鸾道）',
        Remark: '',
        BusStop1: 'The HK Society for the Blind',
        BusStop2: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop3: 'N/A',
        id: 112,
    },
    {
        routeNo: '93K',
        EN: 'Mong Kok East Station - Po Lam',
        TC: '旺角東站 - 寶琳',
        SC: '旺角东站 - 宝琳',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 115,
    },
    {
        routeNo: '93K',
        EN: 'Po Lam - Mong Kok East Station',
        TC: '寶琳 - 旺角東站',
        SC: '宝琳 - 旺角东站',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Sung Wong Toi Park (Platform C)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 116,
    },
    {
        routeNo: '98C',
        EN: 'Hang Hau (North) (Tseung Kwan O Hospital) - Mei Foo',
        TC: '坑口（北）（將軍澳醫院）- 美孚',
        SC: '坑口（北）（将军澳医院）- 美孚',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'Argyle Street Playground (Argyle Street outside Argyle Street Playground)',
        BusStop3: 'N/A',
        id: 117,
    },
    {
        routeNo: '98C',
        EN: 'Mei Foo - Hang Hau (North) (Tseung Kwan O Hospital)',
        TC: '美孚 - 坑口（北）（將軍澳醫院）',
        SC: '美孚 - 坑口（北）（将军澳医院）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 118,
    },
    {
        routeNo: 'A22',
        EN: 'Airport - Lam Tin Station',
        TC: '機場 - 藍田站',
        SC: '机场 - 蓝田站',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 121,
    },
    {
        routeNo: 'A22',
        EN: 'Lam Tin Station - Airport (via HZMB Hong Kong Port)',
        TC: '藍田站 - 機場（經港珠澳大橋香港口岸）',
        SC: '蓝田站 - 机场（经港珠澳大桥香港口岸）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 122,
    },
    {
        routeNo: 'E23',
        EN: 'Airport (Ground Transportation Centre) - Tsz Wan Shan (South)',
        TC: '機場（地面運輸中心）- 慈雲山（南）',
        SC: '机场（地面运输中心）- 慈云山（南）',
        Remark: 'Citybus',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 123,
    },
    {
        routeNo: 'E23',
        EN: 'Tsz Wan Shan (South) - Airport (Ground Transportation Centre)',
        TC: '慈雲山（南）- 機場（地面運輸中心）',
        SC: '慈云山（南）- 机场（地面运输中心）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 124,
    },
    {
        routeNo: 'E23A',
        EN: 'Airport - Tsz Wan Shan (South)',
        TC: '機場 - 慈雲山（南）',
        SC: '机场 - 慈云山（南）',
        Remark: 'Citybus',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 125,
    },
    {
        routeNo: 'E23A',
        EN: 'Tsz Wan Shan (South) - Airport (via Tung Chung North)',
        TC: '慈雲山（南）- 機場（經東涌北）',
        SC: '慈云山（南）- 机场（经东涌北）',
        Remark: 'Citybus',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 126,
    },
    {
        routeNo: 'N121',
        EN: 'Central (Macau Ferry) - Ngau Tau Kok',
        TC: '中環（港澳碼頭）- 牛頭角',
        SC: '中环（港澳码头）- 牛头角',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 129,
    },
    {
        routeNo: 'N121',
        EN: 'Ngau Tau Kok - Central (Macau Ferry)',
        TC: '牛頭角 - 中環（港澳碼頭）',
        SC: '牛头角 - 中环（港澳码头）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 130,
    },
    {
        routeNo: 'N213',
        EN: 'Tsim Sha Tsui East (Mody Road) - On Tai (West)',
        TC: '尖沙咀東（麼地道）- 安泰（西）',
        SC: '尖沙咀东（么地道）- 安泰（西）',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 132,
    },
    {
        routeNo: 'N216',
        EN: 'Hung Hom Station - Yau Tong',
        TC: '紅磡站 - 油塘',
        SC: '红磡站 - 油塘',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 133,
    },
    {
        routeNo: 'N216',
        EN: 'Yau Tong - Hung Hom Station',
        TC: '油塘 - 紅磡站',
        SC: '油塘 - 红磡站',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 134,
    },
    {
        routeNo: 'N293',
        EN: 'Mong Kok (Park Avenue) - Sheung Tak',
        TC: '旺角（柏景灣）- 尚德',
        SC: '旺角（柏景湾）- 尚德',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 137,
    },
    {
        routeNo: 'N293',
        EN: 'Sheung Tak - Mong Kok (Park Avenue)',
        TC: '尚德 - 旺角（柏景灣）',
        SC: '尚德 - 旺角（柏景湾）',
        Remark: '',
        BusStop1: 'Kowloon City BBI - Regal Oriental Hotel (Platform B)',
        BusStop2: 'N/A',
        BusStop3: 'N/A',
        id: 138,
    },
    {
        routeNo: 'N796',
        EN: 'Hong Kong Science Museum, Chatham Road South - Lohas Park',
        TC: '香港科學館，漆咸道南 - 日出康城',
        SC: '香港科学馆，漆咸道南 - 日出康城',
        Remark: '',
        BusStop1: 'Argyle Street Playground (Argyle Street outside Katherine Building)',
        BusStop2: 'Kowloon City BBI - Regal Oriental Hotel (Platform A)',
        BusStop3: 'N/A',
        id: 139,
    },
]

const sortedList = busList.sort((itemA, itemB) => {
    const matchA = itemA.routeNo.match(/([A-Za-z]*)(\d+)([A-Za-z]*)/)
    const matchB = itemB.routeNo.match(/([A-Za-z]*)(\d+)([A-Za-z]*)/)

    const prefixA = matchA ? matchA[1] : ''
    const numA = parseInt(matchA ? matchA[2] : itemA.routeNo)
    const suffixA = matchA ? matchA[3] : ''

    const prefixB = matchB ? matchB[1] : ''
    const numB = parseInt(matchB ? matchB[2] : itemB.routeNo)
    const suffixB = matchB ? matchB[3] : ''

    const prefixComparison = prefixA.localeCompare(prefixB)
    if (prefixComparison !== 0) {
        return prefixComparison
    }

    if (numA === numB) {
        return suffixA.localeCompare(suffixB)
    } else {
        return numA - numB
    }
})

export default sortedList
