import React from 'react'
import { useTranslation } from 'react-i18next'

import MTREn_Tc from 'resources/images/MTREn_Tc.png'
import MTRSc from 'resources/images/MTRSc.png'
import WrapBox from 'page/Transportation/components/WrapBox'
import TextContainer from 'page/Transportation/components/TextContainer'
import WebBtn from 'page/Transportation/components/WebBtn'
const SubTextContainer = ({ title, prefix, bold, suffix }) => {
    return (
        <div className='space-y-2'>
            <div className='text-xl font-semiboldFamily'>{title}</div>
            <div className="flex items-center">
                <span className='text-base font-medium'>
                    {prefix}
                    <span className='text-base font-bold'>{bold}</span>
                    {suffix}
                </span>
            </div>
        </div>
    )
}
function MTR() {
    const { i18n: { language }, t } = useTranslation()
    return (
        <WrapBox
            image={language === 'zh_CN' ? MTRSc : MTREn_Tc}
            component={
                <div className='space-y-8'>
                    <div>
                        <TextContainer title={t('transport.byMtr')} about={t('transport.mtrIntro')} />
                        <WebBtn text={t('transport.mtrWeb')} url={t('transport.mtrLink')} />
                    </div>
                </div>
            }
            bomComponent={
                <div className='space-y-8 pt-8 md:pt-12'>
                    <SubTextContainer 
                        title={t('transport.kaiMtrStation')} 
                        prefix={t('transport.kaiMtrStationAboutPrefix')}
                        bold={t('transport.kaiMtrStationAboutBold')}
                        suffix={t('transport.kaiMtrStationAboutSuffix')} 
                    />
                    <SubTextContainer
                        title={t('transport.sungWondMtrStation')}
                        prefix={t('transport.sungWondMtrStationAboutPrefix')}
                        bold={t('transport.sungWondMtrStationAboutBold')}
                        suffix={t('transport.sungWondMtrStationAboutSuffix')}
                    />
                </div>
            }
        />
    )
}

export default MTR
