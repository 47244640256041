import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Error from 'page/Main/Error'

import Main from 'page/Main'
import Landing from 'page/Landing'
import ShopDine from 'page/ShopDine'
import ShopDineDetail from 'page/ShopDine/Detail'
import Restaurants from 'page/Restaurants'
import RestaurantsDetail from 'page/Restaurants/Detail'
import CarPark from 'page/CarPark'
import CarParkDetail from 'page/CarPark/Detail'
import Promotions from 'page/Promotions'
import PromotionsDetail from 'page/Promotions/Detail'
import Transportation from 'page/Transportation'
import EventsTickets from 'page/EventsTickets'
import EventsTicketsDetail from 'page/EventsTickets/Detail'
import Venue from 'page/Venue'
import VenueDetail from 'page/Venue/Detail'
import FacilitiesBooking from 'page/FacilitiesBooking'
import FacilitiesBookingDetail from 'page/FacilitiesBooking/Detail'
import Programmes from 'page/Programmes'
import ProgrammesDetail from 'page/Programmes/Detail'

import UserSettings from 'page/UserSettings'
import MyProfile from 'page/UserSettings/MyProfile'
import MyBookmark from 'page/UserSettings/MyBookmark'
import MyRecord from 'page/UserSettings/MyRecord'
import Setting from 'page/UserSettings/Setting'
import EditMyProfile from 'page/UserSettings/EditMyProfile'
import TicketDetail from 'page/UserSettings/TicketDetail'
import ConsentPage from 'page/UserSettings/IAmSmartPage/ConsentPage'
import InstructionPage from 'page/UserSettings/IAmSmartPage/InstructionPage'

import AboutKTSP from 'page/ConnectWithKTSP/AboutKTSP'
import WorkWithUs from 'page/ConnectWithKTSP/WorkWithUs'
import ContactUs from 'page/ConnectWithKTSP/ContactUs'
import WorkWithUsDetails from 'page/ConnectWithKTSP/WorkWithUsDetails'
import NewsRoom from 'page/ConnectWithKTSP/NewsRoom'
import NewsRoomDetail from 'page/ConnectWithKTSP/NewsRoom/Detail'
import OurPartner from 'page/ConnectWithKTSP/OurPartner'
import HeritageMilestone from 'page/ConnectWithKTSP/HeritageMilestone'
import FAQ from 'page/ConnectWithKTSP/FAQ'
import TC from 'page/ConnectWithKTSP/TC'
import PrivacyPolicy from 'page/ConnectWithKTSP/PrivacyPolicy'
import Disclaimer from 'page/ConnectWithKTSP/Disclaimer'
import VisitorTips from 'page/VisitorTips'
import SearchPage from 'page/SearchPage'
import ToApp from 'page/Main/ToApp'
import Friends from 'page/Friends'
import Coupon from "./page/Main/coupon";
import AppVersion from 'page/Main/AppVersion'
const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Main />} errorElement={<Error />}>
            <Route index element={<Navigate to='landing' replace />} />
            <Route path='landing' element={<Landing />} />
            {/* <Route path='search' element={<SearchPage />} /> */}
            <Route path='eventsTickets' element={<EventsTickets />} />
            <Route path='eventsTicketsDetail' element={<EventsTicketsDetail />} />
            <Route path='shops' element={<ShopDine />} />
            <Route path='shopsDetail' element={<ShopDineDetail />} />
            <Route path='restaurants' element={<Restaurants />} />
            <Route path='restaurantsDetail' element={<RestaurantsDetail />} />
            <Route path='carPark' element={<CarPark />} />
            <Route path='carParkDetail' element={<CarParkDetail />} />
            <Route path='promotions' element={<Promotions />} />
            <Route path='programmes' element={<Programmes />} />
            <Route path='programmesDetail' element={<ProgrammesDetail />} />
            <Route path='venue' element={<Venue />} />
            <Route path='venueDetail' element={<VenueDetail />} />
            <Route path='promotionsDetail' element={<PromotionsDetail />} />
            <Route path='visitorTips' element={<VisitorTips />} />
            <Route path='transportation' element={<Transportation />} />
            <Route path='userSettings' element={<UserSettings />}>
                <Route index element={<Navigate to='myProfile' replace />} />
                <Route path='myProfile' element={<MyProfile />} />
                <Route path='myProfile/:emailUuid/:lang/:type/:sysLang/:userInfoKey/:emailId' element={<MyProfile />} />
                {/* <Route path='myBookmark' element={<MyBookmark />} /> */}
                <Route path='myRecord' element={<MyRecord />} />
                <Route path='setting' element={<Setting />} />
                <Route path='editMyProfile' element={<EditMyProfile />} />
                <Route path='ticketDetail' element={<TicketDetail />} />
                <Route path='consentPage' element={<ConsentPage />} />
                <Route path='instructionPage' element={<InstructionPage />} />
            </Route>

            <Route path='connectWithKTSP'>
                <Route path='aboutKTSP' element={<AboutKTSP />} />
                <Route path='workWithUs' element={<WorkWithUs />} />
            </Route>

            <Route path='contactUs' element={<ContactUs />} />
            <Route path='newsRoom' element={<NewsRoom />} />
            <Route path='newsRoomDetail' element={<NewsRoomDetail />} />
            <Route path='ourPartner' element={<OurPartner />} />
            <Route path='heritageMilestone' element={<HeritageMilestone />} />
            <Route path='FAQ' element={<FAQ />} />
            <Route path='TC' element={<TC />} />
            <Route path='privacyPolicy' element={<PrivacyPolicy />} />
            <Route path='disclaimer' element={<Disclaimer />} />
            <Route path='workWithUsDetails' element={<WorkWithUsDetails />} />
            <Route path='facilitiesBooking' element={<FacilitiesBooking />} />
            <Route path='facilitiesBookingDetail' element={<FacilitiesBookingDetail />} />
            <Route path='friends' element={<Friends />} />
        </Route>
        <Route path='toApp' element={<ToApp />} />
        <Route path='coupon/:key/:id' element={<Coupon />} />
        <Route path='appVersion' element={<AppVersion />} />
      </>
    ),
)

export default function App() {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <title>{t('common.Title')}</title>
            </Helmet>
            <RouterProvider router={router} />
        </>
    )
}
