import React from 'react'
import { useNavigate } from 'react-router-dom'
import Label from 'components/headerComponents/card/Label'
import { useTranslation } from 'react-i18next'

import { filterName } from 'utils/filter'
function TopLabelImgCard({ item, setIsFocus }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <div
            onClick={() => {
                if (item.webLinkUrl) {
                    item.newBrowser
                        ? window.open(item.webLinkUrl)
                        : navigate(item.webLinkUrl.replace(process.env.REACT_APP_WEBSITE + '/', ''))
                }
                setIsFocus(false)
            }}
            className='w-full h-full flex flex-col justify-between'
        >
            <div className='flex-1'>
                <Label
                    setIsFocus={setIsFocus}
                    link={item.webLinkUrl}
                    newBrowser={item.newBrowser}
                    label={filterName(item, language)}
                    labelStyle={' pb-3'}
                />
            </div>
            {item.imageUrl && (
                <div className='w-full max-w-[264px] aspect-square group overflow-hidden hover:rounded-tr-[40px] transition-all duration-300'>
                    <img
                        alt=''
                        src={item.imageUrl}
                        className='cursor-pointer w-full h-full active:opacity-50  object-cover group-hover:scale-110 transition-all duration-300'
                    />
                </div>
            )}
        </div>
    )
}

export default TopLabelImgCard
