import React, { memo, useEffect, useMemo, useState } from 'react'
import { Button, Drawer, useMediaQuery } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetCookie } from 'utils/cookieLombok'
import headerLogo from 'resources/images/headerLogo.png'
import { cn } from 'utils/cn'
import { updateUrl } from 'utils/util'
import tw from 'twin.macro'
import { isEmpty } from 'ramda'
import { event } from 'event/event'
import { setDayjsLocale, updateUrlLang } from 'utils/util'

import FriendsComponent from 'components/headerComponents/FriendsComponent'
import { filterName, navigateLink } from 'utils/filter'
function ModalHeader({ isOpen, onClose, profileData, profileIsPending, useData, headerNavs }) {
    const navigate = useNavigate()
    const location = useLocation()

    const refreshToken = GetCookie('ktspSsoRefreshToken')
    const {
        i18n: { language },
        t,
    } = useTranslation()

    const [selectNav, setSelectNav] = useState(null)

    const handleParentClick = (e, item, index) => {
        e.stopPropagation()

        if (item.webLinkUrl) {
            item.newBrowser
                ? window.open(item.webLinkUrl)
                : navigate(item.webLinkUrl.replace(process.env.REACT_APP_WEBSITE + '/', ''))
            onClose()
        } else setSelectNav(index)
    }

    useEffect(() => {
        setSelectNav(null)
        onClose()
    }, [location])
    return (
        <HeaderContainer isOpen={isOpen} onClose={onClose}>
            <div className='flex flex-col w-full md:w-96 h-full px-5 text-white bg-website-gradation '>
                <header className='md:h-[108px] px-[10px] flex justify-between items-center border-b border-b-white/30'>
                    <div className='my-[20.74px] w-[100px] h-[40px]'>
                        <img alt='' className='object-contain w-full h-full' src={headerLogo}></img>
                    </div>
                    <div onClick={onClose} className='icon-close text-white cursor-pointer active:opacity-30'></div>
                </header>
                <main className='flex-1 px-[10px] mt-4 space-y-2 overflow-y-auto'>
                    {(!!selectNav ||
                        (selectNav === 0 &&
                            headerNavs[selectNav].secondMenu &&
                            headerNavs[selectNav].secondMenu.length > 0)) && (
                        <div
                            className='flex items-center gap-2 cursor-pointer h-fit font-semiboldFamily'
                            onClick={() => setSelectNav(null)}
                        >
                            <div className='icon-arrow_left' />
                            {filterName(
                                headerNavs.find((item, i) => i === selectNav),
                                language,
                            )}
                        </div>
                    )}
                    {!selectNav && selectNav !== 0
                        ? headerNavs.map((nav, index) => {
                              return (
                                  <div className='cursor-pointer' key={index}>
                                      {selectNav}
                                      <div
                                          className='flex items-center justify-between w-full h-10 font-semiboldFamily'
                                          onClick={(e) => {
                                              handleParentClick(e, nav, index)
                                          }}
                                      >
                                          {filterName(nav, language)}
                                          {!nav.webLinkUrl && <div className='icon-arrow_right' />}
                                      </div>
                                  </div>
                              )
                          })
                        : headerNavs[selectNav].secondMenu &&
                          headerNavs[selectNav].secondMenu.map((item, index) => {
                              return item.map((navLabel, i) => {
                                  return (
                                      <div className='space-y-2' key={i}>
                                          {navLabel.webLinkUrl && (
                                              <div
                                                  className='flex items-center cursor-pointer justify-between w-full h-10 font-semiboldFamily'
                                                  onClick={() => {
                                                      navLabel.newBrowser
                                                          ? window.open(navLabel.webLinkUrl)
                                                          : navigate(
                                                                navLabel.webLinkUrl.replace(
                                                                    process.env.REACT_APP_WEBSITE + '/',
                                                                    '',
                                                                ),
                                                            )
                                                      onClose()
                                                  }}
                                              >
                                                  {filterName(navLabel, language)}
                                              </div>
                                          )}
                                          {navLabel.threeMenu &&
                                              navLabel.threeMenu.length > 0 &&
                                              navLabel.threeMenu.map((itemThree, iThree) => {
                                                  return (
                                                      <div
                                                          key={iThree}
                                                          className='flex items-center cursor-pointer justify-between w-full h-10 font-semiboldFamily'
                                                          onClick={() => {
                                                              if (itemThree.webLinkUrl) {
                                                                  itemThree.newBrowser
                                                                      ? window.open(itemThree.webLinkUrl)
                                                                      : navigate(
                                                                            itemThree.webLinkUrl.replace(
                                                                                process.env.REACT_APP_WEBSITE + '/',
                                                                                '',
                                                                            ),
                                                                        )
                                                                  onClose()
                                                              }
                                                          }}
                                                      >
                                                          {filterName(itemThree, language)}
                                                      </div>
                                                  )
                                              })}
                                      </div>
                                  )
                              })
                          })}
                    {!selectNav && selectNav !== 0 && (
                        <div className='pt-2 pb-4'>
                            <FriendsComponent className='' />
                        </div>
                    )}
                </main>
                <footer className='flex flex-col gap-6 pb-4 px-[10px]'>
                    {refreshToken && profileData ? (
                        <Auth isPending={profileIsPending} profileData={profileData} useData={useData} />
                    ) : (
                        <Button
                            className='px-3 text-sm w-fit h-fit text-[#fff] border-[#fff] hover:bg-white/10'
                            variant='outlined'
                            onClick={() => {
                                window.location.href =
                                    process.env.NODE_ENV === 'development'
                                        ? `http://localhost:3001/login/signIn?lang=${language}&redirectUrl=http://localhost:3000`
                                        : `${process.env.REACT_APP_CIAM}?lang=${language}&redirectUrl=${process.env.REACT_APP_WEBSITE}`
                            }}
                        >
                            {t('navigationBar.loginBtn')}
                        </Button>
                    )}

                    <div className='flex items-center gap-6'>
                        <LanguageSwitch onClose={onClose} />
                    </div>
                    {/* <SelectComponent color={'white'} /> */}
                </footer>
            </div>
        </HeaderContainer>
    )
}

const HeaderContainer = memo(({ isOpen, onClose, children }) => {
    const isMobile = useMediaQuery('(max-width: 767px)')
    return isMobile ? (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            {children}
        </Modal>
    ) : (
        <Drawer open={isOpen} onClose={onClose} anchor='right' className='max-w-3xl w-full'>
            {children}
        </Drawer>
    )
})

const Avatar = tw.div`text-white w-[42px] h-[42px] cursor-pointer rounded-[21px] bg-[#8F774E] border-solid border-[1px] border-white  flex justify-center items-center text-sm`
const Auth = ({ isPending, profileData, useData }) => {
    const navigate = useNavigate()
    const {
        i18n: { language },
    } = useTranslation()
    const [nickName, setNickName] = useState('')
    const [lastName, setLastName] = useState('')
    const text = useMemo(() => {
        if (nickName && lastName) {
            return lastName.slice(0, 1) + nickName.slice(0, 1)
        } else if (lastName) {
            return lastName.slice(0, 1)
        }
    }, [nickName, lastName])

    useEffect(() => {
        if (!isPending && !isEmpty(profileData)) {
            setNickName(profileData?.firstname)
            setLastName(profileData?.lastname)
        }
    }, [profileData, isPending])

    const [isCollapse, setIsCollapse] = useState(false)

    return (
        <div className='pt-4 border-t border-t-white/30'>
            <div
                className='flex items-center justify-between cursor-pointer'
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <Avatar className='font-semiboldFamily'>{text}</Avatar>
                {isCollapse ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
            </div>
            {!!isCollapse && (
                <div className='pt-5'>
                    {useData &&
                        useData.length > 0 &&
                        useData.map((item, index) => {
                            return (
                                <div
                                    className='flex items-center cursor-pointer w-full h-10 font-semiboldFamily'
                                    onClick={() => {
                                        item?.platform === 'VBS'
                                            ? window.open(updateUrlLang(item.linkUrl))
                                            : !item.linkUrl.includes('/Logout')
                                            ? navigateLink(navigate, 0, item.linkUrl)
                                            : event.emit('logout')
                                    }}
                                    key={index}
                                >
                                    <div className='flex items-center gap-2 text-white'>
                                        <img src={item.iconMobileUrl} alt='' className='size-6 object-contain' />
                                        {filterName(item, language)}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            )}
        </div>
    )
}
const LanguageSwitch = ({ onClose }) => {
    const { i18n } = useTranslation()

    const lngs = [
        { title: '繁', value: 'zh_HK' },
        { title: '简', value: 'zh_CN' },
        { title: 'EN', value: 'en_US' },
    ]

    const handleChange = (value) => {
        setDayjsLocale(value)
        i18n.changeLanguage(value)
        updateUrl()
        onClose()
    }

    return (
        <div className='flex items-center gap-4'>
            {lngs.map((item) => (
                <span
                    className={cn(i18n.language === item.value && 'underline underline-offset-2') + ' cursor-pointer'}
                    onClick={() => handleChange(item.value)}
                    key={item.value}
                >
                    {item.title}
                </span>
            ))}
        </div>
    )
}

export default ModalHeader
