import React from 'react'
import { Button, useMediaQuery } from '@mui/material'

import { cn } from 'utils/cn'
import Content from 'components/Content'
import { Trans, useTranslation } from 'react-i18next'
import DateComponent from 'components/detailComponents/DateComponent'
import { updateUrlLang } from 'utils/util'
import balloting from 'resources/images/balloting.svg'
function BuyNowBtn({ items, datesFormat, times }) {
    const { t } = useTranslation()
    const { type, ballotLink, bottonRemarkTitle, bottonRemarkDescription, ctaButtonUrl, ctaButtonStatus } = items
    const isPC = useMediaQuery('(min-width: 1024px)')
    if (!type) {
        return null
    }
    const RemarkSection = ({ visibilityClass }) => {
        return (
            <div className={`px-5 text-secondary-midnightBlue bg-[#E9ECF3] w-full py-3 ${visibilityClass}`}>
                <div className='flex gap-2 items-center pb-2'>
                    {/* <div className='icon-balleting text-2xl leading-6' /> */}
                    <img src={balloting} alt='balloting' className='w-6' />
                    <div className='text-body1 font-semiboldFamily'>{t('facilitiesBooking.ballotingResult')}</div>
                </div>
                <div className='text-xs font-medium break-words'>
                    <Trans
                        i18nKey='facilitiesBooking.ballotingDescription'
                        components={[
                            <a
                                href={ballotLink}
                                className='underline font-semibold'
                                key={ballotLink}
                                aria-label='View the Balloting Result'
                            />,
                        ]}
                    />
                </div>
            </div>
        )
    }

    return (
        <div
            className={cn(
                'sticky bottom-0 left-0 right-0 bg-white z-40 pt-4 flex-1 lg:static lg:pt-[96px]',
                isPC ? '' : 'shadow-card',
            )}
        >
            <div className='flex flex-col-reverse px-5 gap-4 md:px-[60px] lg:gap-6 lg:min-h-[317px] lg:flex-col lg:border-[#0A173D] lg:pl-8 lg:pr-0 lg:border-solid lg:border-l-[1px] '>
                {type?.includes('FCFS') ? (
                    <>
                        <div className='flex gap-4 flex-col pb-4 md:items-start md:justify-between lg:items-start lg:gap-6 lg:pb-0'>
                            <DateComponent datesFormat={datesFormat} times={times} />
                            {type?.includes('BALLOT') && <RemarkSection />}
                            <Button
                                disabled={!ctaButtonStatus}
                                onClick={() => {
                                    if (ctaButtonUrl) {
                                        window.location.href = updateUrlLang(ctaButtonUrl)
                                    }
                                }}
                                className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                                variant={ctaButtonStatus ? 'primaryRed' : 'unselected'}
                                fullWidth
                                size='large'
                            >
                                {!ctaButtonStatus ? t('facilitiesBooking.full') : t('facilitiesBooking.bookNow')}
                            </Button>
                        </div>
                        {(bottonRemarkTitle || bottonRemarkDescription) && (
                            <div className='px-5 text-secondary-midnightBlue bg-[#E9ECF3] w-full py-3 lg:py-4 lg:w-[332px]'>
                                {bottonRemarkTitle && (
                                    <div className='flex gap-2 items-center pb-2 lg:pb-3'>
                                        <div className='icon-ticket text-2xl leading-6' />
                                        <div className='text-body1 font-semiboldFamily'>{bottonRemarkTitle}</div>
                                    </div>
                                )}
                                {bottonRemarkDescription && (
                                    <Content
                                        className='text-xs font-medium  break-words'
                                        data={bottonRemarkDescription}
                                    />
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className='flex gap-4 flex-col pb-4'>
                            <RemarkSection visibilityClass='block lg:hidden' />

                            <div className='w-full flex gap-4 lg:gap-6 flex-col md:flex-row lg:flex-col items-start md:items-center lg:items-start'>
                                <DateComponent datesFormat={datesFormat} times={times} />

                                <RemarkSection visibilityClass='hidden lg:block' />

                                <Button
                                    disabled={!ctaButtonStatus}
                                    onClick={() => {
                                        if (ctaButtonUrl) {
                                            window.location.href = ctaButtonUrl
                                        }
                                    }}
                                    className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                                    variant={ctaButtonStatus ? 'primaryRed' : 'unselected'}
                                    fullWidth
                                    size='large'
                                >
                                    {t('programme.ballotNow')}
                                </Button>
                            </div>
                        </div>

                        {/* <div className='flex flex-col gap-4 lg:gap-6 pb-4'>
                            {(bottonRemarkTitle || bottonRemarkDescription) && (
                                <div className='order-1 md:order-1 lg:order-2 px-5 text-secondary-midnightBlue bg-[#E9ECF3] w-full py-3 lg:py-4 lg:w-[332px]'>
                                    <div className='flex gap-2 items-center pb-2 lg:pb-3'>
                                        {bottonRemarkTitle && (
                                            <>
                                                <div className='icon-ticket text-2xl leading-6' />
                                                <div className='text-body1 font-semiboldFamily'>
                                                    {bottonRemarkTitle}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {bottonRemarkDescription && (
                                        <Content
                                            className='text-xs font-medium break-words'
                                            data={bottonRemarkDescription}
                                        />
                                    )}
                                </div>
                            )}

                            <div className='order-2 md:order-2 lg:order-1 flex flex-col md:flex-row lg:flex-col gap-4 lg:gap-6 lg:items-start flex-1'>
                                {datesFormat && (
                                    <div className='font-semiboldFamily text-2xl lg:text-4xl'>
                                        {datesFormat}
                                    </div>
                                )}

                                <Button
                                    disabled={ctaButtonStatus !== 1}
                                    onClick={() => {
                                        if (ctaButtonUrl) {
                                            window.location.href = ctaButtonUrl
                                        }
                                    }}
                                    className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                                    variant={ctaButtonStatus === 1 ? 'primaryRed' : 'unselected'}
                                    fullWidth
                                    size='large'
                                >
                                    {ctaButtonWording}
                                </Button>
                            </div>
                        </div> */}
                    </>
                )}
            </div>
        </div>
    )
}

export default BuyNowBtn
