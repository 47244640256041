import React, { useState } from 'react'
import { Button, useMediaQuery } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'

import { formatStartDateEndDate } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
import BuyNowBtn from './BuyNowBtn'
import { cx } from 'class-variance-authority'
import VenueCard from 'page/Venue/components/VenueCard'
import VenueComponent from './VenueComponent'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const Title = tw.div`w-[116px] text-sm md:text-base`
function TopIntro({ items, status }) {
    const {
        facilitiesName,
        // dateRange,
        startDate,
        endDate,
        startTime,
        endTime,
        local,
        subLocal,
        specialRemarkDescription,
        specialRemarkTitle,
        venueList,
        venueImage,
        venueLocationUrl,
        enquiries,
        email,
        bottonRemarkDescription,
        bottonRemarkTitle,
        ballotLink,
    } = items

    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')
    const statusDatesFormat =
        status?.startDate && status?.endDate && formatStartDateEndDate(status.startDate, status.endDate, language)

    const buttonStatus = {
        type: status?.enrlMethod || '',
        ballotLink,
        ctaButtonUrl: status?.actionLink || '',
        ctaButtonStatus: status?.actionLink && status?.actionLink !== 'disabled',
        bottonRemarkTitle,
        bottonRemarkDescription,
    }

    const maoUrl = (longitude, latitude) => {
        return language === 'zh_CN'
            ? `https://uri.amap.com/marker?position=${longitude},${latitude}`
            : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    }

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[800px] md:pt-8 md:pb-[48px]'>
                <NavigationPoint
                    firstNav={t('bookingsProgrammes.facility')}
                    lastNav={facilitiesName}
                    firstNavLink={'/facilitiesBooking'}
                />
                <h1 className='text-h3 w-full text-secondary-midnightBlue py-4'>{facilitiesName}</h1>
                {(specialRemarkTitle || specialRemarkDescription) && (
                    <ExclusiveOffer
                        text={t('facilitiesBooking.seeMore')}
                        title={specialRemarkTitle}
                        about={specialRemarkDescription}
                    />
                )}
                {startTime && endTime && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('eventPage.time')}</Title>
                        <div className='flex-1 text-body1'>{startTime + ' - ' + endTime}</div>
                    </div>
                )}

                {venueList &&
                    venueList.length > 0 &&
                    venueList.map((i, index) => {
                        return <VenueComponent key={index} index={index} venue={i} />
                    })}

                {enquiries && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('facilitiesBooking.Enquiries')}</Title>
                        <div className='text-body1'>{enquiries}</div>
                    </div>
                )}
                {email && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('facilitiesBooking.email')}</Title>
                        <div className='text-body1'>{email}</div>
                    </div>
                )}
            </div>

            <>
                {isPC ? (
                    <BuyNowBtn
                        times={
                            status?.startDate && status?.endDate
                                ? [
                                      {
                                          eventStartDateTime: status.startDate ?? '',
                                          eventEndDateTime: status.endDate ?? '',
                                      },
                                  ]
                                : []
                        }
                        items={buttonStatus}
                        datesFormat={statusDatesFormat}
                    />
                ) : (
                    createPortal(
                        <BuyNowBtn
                            times={
                                status?.startDate && status?.endDate
                                    ? [
                                          {
                                              eventStartDateTime: status.startDate ?? '',
                                              eventEndDateTime: status.endDate ?? '',
                                          },
                                      ]
                                    : []
                            }
                            items={buttonStatus}
                            datesFormat={statusDatesFormat}
                        />,
                        document.getElementById('main-container'),
                    )
                )}
            </>
        </div>
    )
}

export default TopIntro
