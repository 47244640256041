import { Box, Button, Tab, Tabs } from '@mui/material'
import React, { useState, useRef, useEffect, createRef } from 'react'
import Sticky from 'react-stickynode'
import { cn } from 'utils/cn'

/**
 * @typedef {Object} ComponentItem
 * @property {string} key - Unique identifier for the component
 * @property {string} title - Title to be displayed in the tab
 * @property {React.ComponentType} component - React component to be rendered
 */

/**
 * TabContainer component that renders a set of tabs and corresponding components.
 * It provides smooth scrolling to components when tabs are clicked and
 * updates the active tab based on scroll position.
 *
 * @param {Object} props - The component props
 * @param {ComponentItem[]} props.components - Array of component items to be rendered
 * @param {number} [props.headerHeight=108] - Height of the website header component
 * @param {number} [props.tabHeight=48] - Height of the tab component
 * @returns {React.ReactElement} The rendered TabContainer component
 */
export const TabContainer = ({
    components,
    headerHeight = 108,
    tabHeight = 48,
    fullWidth = false,
    customDistance = 0,
}) => {
    const [activeTab, setActiveTab] = useState(components[0].key)
    const componentRefs = useRef(components.map(() => createRef()))
    const [isSticky, setIsSticky] = useState(true)
    const [isScroll, setIsScroll] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (isScroll) {
                return
            }
            const scrollPosition = window.scrollY + tabHeight
            const viewportHeight = window.innerHeight
            const oneThirdViewportHeight = viewportHeight / 3

            componentRefs.current.forEach((ref, index) => {
                if (ref.current) {
                    const element = ref.current
                    const { offsetTop, offsetHeight } = element

                    if (
                        scrollPosition >= offsetTop - oneThirdViewportHeight &&
                        scrollPosition < offsetTop + offsetHeight - oneThirdViewportHeight
                    ) {
                        setActiveTab(components[index].key)
                    }
                }
            })

            // 检查是否滚动到底部
            const bottomScrollPosition = window.scrollY + window.innerHeight
            const documentHeight = document.documentElement.scrollHeight
            const isBottom = bottomScrollPosition >= documentHeight - 10 // 允许10px的误差

            setIsSticky(!isBottom)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [components, headerHeight, tabHeight, isScroll])

    /**
     * Handles tab click events
     * @param {string} key - The key of the clicked tab
     */
    const handleTabClick = (key) => {
        if (isScroll) {
            return
        }
        setIsScroll(true)
        setActiveTab(key)
        const index = components.findIndex((comp) => comp.key === key)
        if (componentRefs?.current?.[index]?.current) {
            const element = componentRefs.current[index].current
            const elementRect = element.getBoundingClientRect()
            const absoluteElementTop = elementRect.top + window.scrollY
            const oneThirdViewportHeight = window.innerHeight / 3
            const scrollTo = absoluteElementTop - oneThirdViewportHeight - customDistance

            window.scrollTo({
                top: Math.max(0, scrollTo),
                behavior: 'smooth',
            })
        }
        setTimeout(() => setIsScroll(false), 500)
    }

    return (
        <div>
            <Sticky
                enabled={isSticky}
                top='#main-header'
                bottomBoundary='#main-content'
                activeClass='bg-white'
                innerClass='z-40'
            >
                <div className='flex flex-row items-center justify-center bg-white mx-0'>
                    <div className='bg-container flex'>
                        <Tabs
                            textColor='secondary'
                            indicatorColor='secondary'
                            TabIndicatorProps={{
                                sx: {
                                    height: '4px',
                                    borderTopRightRadius: '4px',
                                    backgroundColor: 'secondary',
                                },
                            }}
                            value={activeTab}
                            scrollButtons={false}
                            variant='scrollable'
                            className={cn(
                                'flex-1 border-solid bg-white border-b-[1px] border-secondary-darkGery',
                                fullWidth ? 'w-full' : 'max-w-[750px]',
                            )}
                            onChange={(e, v) => setActiveTab(v)}
                            aria-label='basic tabs example'
                        >
                            {components.map(({ key, title }) => {
                                return (
                                    <Tab
                                        sx={{
                                            fontWeight: activeTab === key ? 600 : 500,
                                            textTransform: 'none',
                                            alignItems: 'flex-start',
                                            padding: 0,
                                            marginRight: title ? '39px' : '0px',
                                            minWidth: 0,
                                        }}
                                        key={key}
                                        value={key}
                                        label={title}
                                        onClick={() => handleTabClick(key)}
                                    />
                                )
                            })}
                        </Tabs>
                        <Button
                            className={cn('hidden opacity-0 pl-6', fullWidth ? '' : 'lg:inline-block')}
                            variant='gradation'
                        >
                            placeholder
                        </Button>
                    </div>
                </div>
            </Sticky>

            <div>
                {components.map(({ key, component: Component }, index) => (
                    <div key={key} ref={componentRefs.current[index]}>
                        <Component />
                    </div>
                ))}
            </div>
        </div>
    )
}
